<template>
  <div id="modal-confirm" class="modal fade">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span>×</span>
          </button>
          <slot name="header" />
        </div>
        <div class="form-horizontal">
          <div class="modal-body">
            <slot name="content" />
          </div>
          <div class="modal-footer center">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirm',
  methods: {
    close() {
      document.getElementById('close').click()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <main id="project">
    <div class="container">
      <Step :step="2" />
      <div class="row">
        <div class="col-sm-12">
          <div class="panel">
            <div class="panel-heading">
              <h2 class="pageTitle">
                入力画面<span>Customize</span>
              </h2>
            </div>
            <div class="panel-body custom_input">
              <h3 class="menuannounce">
                任意のキャラクター名と任意のカテゴリー名の両方を入力(最大5つまで)
              </h3>
              <p class="dbnotice">
                「計算する」ボタンを押すと、ご入力いただいたキャラクター名とカテゴリー名がデータベースに存在するかの確認を行います。<br>
                存在しない場合はエラーが通知されますので、新しいワードを入力するか、空欄にして再度計算するを押してください。<br>
                注）少なくとも1つのキャラクター名とカテゴリー名が必要になります。
              </p>
              <div v-if="messageError" class="dbpoint">
                <p>{{ messageError }}</p>
              </div>
              <div class="input_list -ttl spNone">
                <div class="input_num" />
                <div class="input_item">
                  キャラクター名
                </div>
                <div class="input_item">
                  カテゴリー
                </div>
              </div>
              <template v-if="categories.length > 0 && characters.length > 0">
                <div v-for="(item, index) in maxRows" :key="index" class="input_list form-group">
                  <template v-if="index <= maxIndexRow">
                    <div class="input_num">
                      {{ item }}.
                    </div>
                    <div class="input_item">
                      <input
                        v-model="characters[index].value"
                        type="text"
                        :class="['form-control', characters[index].error ? 'errorproject' : '']"
                        placeholder="キャラクター名"
                        maxlength="255"
                        @input="characters[index].error = false"
                      >
                    </div>
                    <div class="input_item">
                      <input
                        v-model="categories[index].value"
                        type="text"
                        :class="['form-control', categories[index].error ? 'errorproject' : '']"
                        placeholder="カテゴリー"
                        maxlength="255"
                        @input="categories[index].error = false"
                      >
                    </div>
                  </template>
                </div>
              </template>
              <div>
                <ul class="actionVertical clearfix">
                  <li>
                    <button type="submit" class="btn btn01" @click="submit">
                      計算する
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn btn02" @click="goToSelectMenu">
                      戻る
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm ref="modalConfirm">
      <div slot="header">
        <h4 class="modal-title">
          plug-ip.com
        </h4>
      </div>
      <div slot="content">
        <p>全てのワードがデータベースにあります。計算しますか？</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-darkblue" data-dismiss="modal" @click="calculation">
          OK
        </button>
        <button type="button" class="btn btn-darkgray" data-dismiss="modal">
          キャンセル
        </button>
      </div>
    </ModalConfirm>
    <button id="button-toggle-modal" style="display: none" data-toggle="modal" data-target="#modal-confirm" />
  </main>
</template>

<script>
import store from '../../store'
import Step from '../../components/project/Step'
import { actions as actionsProject } from '../../store/modules/project'
import { maxRows } from '../../common/constant'
import { createProjectApi, validateWordApi } from '../../services/api'
import { actions as actionsApp } from '../../store/modules/app'
import { trimValue } from '../../shared/common'
import ModalConfirm from '../../components/modal/ModalConfirm'
import { actions as actionsUser } from '../../store/modules/user'
import router from '../../routers'

export default {
  name: 'CustomInput',
  components: {
    Step, ModalConfirm
  },
  data() {
    return {
      maxRows,
      categories: [],
      characters: [],
      maxIndexRow: 0,
      listTextError: [],
      messageError: ''
    }
  },
  watch: {
    categories: {
      handler: function(value) {
        this.checkShowRow()
        store.dispatch(actionsProject.SET_CATEGORIES_CUSTOM_INPUT, value)
      },
      deep: true
    },
    characters: {
      handler: function(value) {
        this.checkShowRow()
        store.dispatch(actionsProject.SET_CHARACTERS_CUSTOM_INPUT, value)
      },
      deep: true
    },
    messageError(value) {
      store.dispatch(actionsProject.SET_MESSAGE_ERROR_CUSTOM_INPUT, value)
    }
  },
  async created() {
    if (!store.getters.menu) {
      await this.$router.push({ name: 'SelectMenu' })
      this.$destroy()
      return false
    }
  },
  mounted() {
    this.initData()
    this.checkShowRow()
  },
  methods: {
    goToSelectMenu() {
      this.$router.push({ name: 'SelectMenu' })
    },
    initData() {
      this.messageError = store.getters.messageErrorCustomInput
      if (store.getters.categoriesCustomInput.length === 0) {
        const arrCategory = []
        for (let i = 0; i < this.maxRows; i++) {
          arrCategory.push({ value: '', error: false })
        }
        this.categories = arrCategory
      } else {
        this.categories = store.getters.categoriesCustomInput
      }

      if (store.getters.charactersCustomInput.length === 0) {
        const arrCharacter = []
        for (let i = 0; i < this.maxRows; i++) {
          arrCharacter.push({ value: '', error: false })
        }
        this.characters = arrCharacter
      } else {
        this.characters = store.getters.charactersCustomInput
      }
    },
    checkShowRow() {
      this.maxIndexRow = 0
      for (let i = 0; i < this.maxRows; i++) {
        if (this.categories[i].value || this.characters[i].value) {
          this.maxIndexRow = i === this.maxRows - 1 ? i : i + 1
        }
      }
    },
    async submit() {
      this.categories = trimValue(this.categories)
      this.characters = trimValue(this.characters)
      await this.validateData()
      if (!this.messageError) {
        document.getElementById('button-toggle-modal').click()
      }
    },
    async calculation() {
      await store.dispatch(actionsProject.SET_CATEGORIES_CUSTOM_INPUT_VALID, this.categories)
      await store.dispatch(actionsProject.SET_CHARACTERS_CUSTOM_INPUT_VALID, this.characters)

      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await createProjectApi({
        menu: store.getters.menu,
        categories: store.getters.categoriesForSubmit,
        characters: store.getters.charactersForSubmit
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        await store.dispatch(actionsProject.SET_RESULT, res.data)
        await this.$router.push({
          name: 'Result'
        })
      } else if (res.status === 401) {
        await store.dispatch(actionsUser.LOGOUT)
        await router.push({ name: 'login' })
      } else {
        alert('エラーが発生しました。 もう一度やり直してください')
      }
    },
    async validateData() {
      this.messageError = ''
      const validCategories = this.categories.filter(item => item.value !== '')
      if (validCategories.length === 0) {
        this.messageError = 'カテゴリー名を入力してください。'
        return false
      }
      const validCharacters = this.characters.filter(item => item.value !== '')
      if (validCharacters.length === 0) {
        this.messageError = 'キャラクター名を入力してください。'
        return false
      }
      const listWordError = []
      const arrayValueCategory = this.categories.map(item => item.value)
      const arrayValueCharacter = this.characters.map(item => item.value)
      const words = arrayValueCategory.concat(arrayValueCharacter).filter(item => item !== '')
      const uniqueWords = [...new Set(words)]

      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await validateWordApi({
        words: uniqueWords
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200 && res.data.length > 0) {
        this.categories.forEach(category => {
          if (res.data.includes(category.value) && category.value !== '') {
            category.error = true
            listWordError.push(category.value)
          }
        })

        this.characters.forEach(character => {
          if (res.data.includes(character.value) && character.value !== '') {
            character.error = true
            listWordError.push(character.value)
          }
        })
      }

      if (listWordError.length > 0) {
        this.messageError = `申し訳ございませんが、「${listWordError.join(', ')}」はデータベースに入っておりません。`
      }
    },
    checkIsExistData(array) {
      let result = false
      array.forEach(item => {
        if (item.value) result = true
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="row bs-wizard" style="border-bottom:0;">
    <div :class="['col-xs-2', 'bs-wizard-step', step === 1 ? 'active' : step > 1 ? 'complete' : 'disabled']">
      <div class="text-center bs-wizard-stepnum">
        Step 1
      </div>
      <div class="progress">
        <div class="progress-bar" />
      </div> <a href="#" class="bs-wizard-dot" />
      <div class="bs-wizard-info text-center">
        メニュー選択
      </div>
    </div>
    <div :class="['col-xs-2', 'bs-wizard-step', step === 2 ? 'active' : step > 2 ? 'complete' : 'disabled']">
      <div class="text-center bs-wizard-stepnum">
        Step 2
      </div>
      <div class="progress">
        <div class="progress-bar" />
      </div> <a href="#" class="bs-wizard-dot" />
      <div class="bs-wizard-info text-center">
        入力・確認
      </div>
    </div>
    <div :class="['col-xs-2', 'bs-wizard-step', step === 3 ? 'active' : step > 3 ? 'complete' : 'disabled']">
      <div class="text-center bs-wizard-stepnum">
        Step 3
      </div>
      <div class="progress">
        <div class="progress-bar" />
      </div> <a href="#" class="bs-wizard-dot" />
      <div class="bs-wizard-info text-center">
        結果表示
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
  props: {
    step: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style scoped>

</style>
